<template>
  <div class="green-border-box in-padd white-bg" style="min-height:500px;">
    <div style="padding:1%;">
      <h2 style="text-align: center;">Campaigns Calendar </h2>
      <FullCalendar :options='calendarOptions' />
    </div>
  </div>
</template>

<script >
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data () {
    return {
      brands: [],
      brandId: '',
      brandLimitPerPage: 1000,
      brandOffset: 0,
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        select: this.handleDateSelect,
        eventsSet: this.handleEvents,
        events: []
      }
    }
  },
  mounted () {
    this.getCampaings()
  },
  methods: {
    getCampaings () {
      this.brandId = this.$store.state.userData.brandId
      this.$store.dispatch('getCampaignCalendarData', this.brandId).then(response => {
        this.calendarOptions.events = response.body
      })
    },
    handleEventClick (e) {
      console.log(e.event.extendedProps)
      if (e.event.extendedProps.type === 'GEO') {
        this.$router.push('/brand/campaigns/' + e.event.id + '/pickls')
      } else {
        this.$router.push('/brand/non-geo-pickl/' + e.event.extendedProps.picklId)
      }
    }
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "green-border-box in-padd white-bg",
      staticStyle: { "min-height": "500px" }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "1%" } },
        [
          _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Campaigns Calendar ")
          ]),
          _c("FullCalendar", { attrs: { options: _vm.calendarOptions } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }